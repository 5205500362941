.district-map-portugal {
    height: 32em;
    width: auto;
    margin: 0;
}

svg.district-map-portugal g path{
    fill: #DDD;
    stroke: #FFF;
}

svg.district-map-portugal g text{
    fill: #000;
    line-height: 0;
    font-family:sans-serif;
    font-size: smaller;
    position: absolute;
    z-index: 10;
    text-align:center;
    writing-mode:lr-tb;
    text-anchor:middle;
    fill-opacity: 1;
    stroke-width: 1px;
    stroke: none;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1;
}

svg.district-map-portugal g.active path {
    fill: #ff783b;
}

svg.district-map-portugal g.active text {
    text-shadow: #FFF 0.05em 0.05em 0.01em;
}

svg.district-map-portugal, svg.district-map-portugal * {
    cursor: default;
    user-select: none;
}

svg.district-map-portugal.editable, svg.district-map-portugal.editable * {
   cursor: pointer;
}
