/* GENERAL STYLES */
:root {
  --empxxi-accent-color: #eb4f24;
  --empxxi-accent-color-25opacity: rgba(235, 79, 36, .25);
  --empxxi-focus-link-color: #a6251b;
}

body {
  background: white;
  font-family: Arial, sans-serif;
}

strong,
.font-weight-bold {
  font-family: Arial, sans-serif;
  font-weight: bold;
}

.is-invalid .react-datetime-picker__wrapper {
  border: 1px solid #dc3545;
}

#content {
  min-height: 400px;
  animation: fadein 0.6s;
  position: relative;
}

.financialBoards button.accordion-button {
  background: #eee !important;
}

.financialBoards button.accordion-button:hover {
  background: #ddd !important;
}


body .decimalInput:disabled {
  border: 1px solid #ced4da !important;
  padding: 0.375rem 0.25rem !important;
  background: #e9ecef !important;
}

.decimalInput{
  padding-left: 0.25rem;
  padding-right: 0.25rem;

}

.form p{
  overflow-wrap: break-word;
}

.intIput div input:disabled {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
  padding: 0.375rem 0.75rem !important;
}


body .text-primary {
  color: #eb4f24 !important;
}

body a,
.btn-link {
  color: #eb4f24;
}

a:hover,
a:focus,
.btn-link:hover,
.btn-link:focus {
  color: #a6251b;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
  background: none;
  border-color: transparent;
}

.bg-primary {
  background: #eb4f24 !important;
}

.bg-primary a {
  color: #fff !important;
}

nav img {
  max-width: 200px;
}


.homepage-message {
  position: fixed;
  bottom: 0;
  text-align: center;
  z-index: 999;
  width: 100%;
  padding: 10px 3px;
  color: white;
  font-weight: bold;
  background: #eb4f24;
  font-size: 1.1rem;
  text-transform: uppercase;
  box-shadow: 1px 0 6px rgba(0,0,0,0.5);
}

h6 {
  font-weight: normal;
  text-transform: uppercase;
}

.list-header {background: #eee; text-transform: uppercase; font-size:0.8rem; padding: 3px 5px; color: #555}

.page-title {
  font-weight: normal;
  padding-bottom: 1rem;
  padding-top: 2.2rem;
}

.page-title h1 {
  font-weight: normal;
  margin-bottom: 0;
  font-size: 1.5rem;
}

.page-title p {
  color: #666;
}

img {
  max-width: 100%;
}

/* SECTIONS */
#o-que-e {
  padding-top: 6rem;
  padding-bottom: 8rem;
}

#destinatarios {
  padding-top: 5rem;
}

#destinatarios {
  background: #ffe5d9;
}

#destinatarios h2 {
  color: #ff783b;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4rem;
}

#destinatarios img {
  max-width: 360px;
  border-radius: 20px;
}

#documentacao {
  background: #ffe5d9;
  padding-bottom: 4rem;
}

#documentacao h3 {
  color: #ff783b;
  text-transform: uppercase;
}

#documentacao a {
  color: #212529;
}

/* BUTTONS*/
button,
button:hover,
button:focus {
  outline: 0 !important;
}

.btn {
  border-radius: 50px;
}

.btn-primary {
  color: #fff;
  background-color: #eb4f24;
  border-color: #eb4f24;
}

.btn-primary:hover,
.btn-primary:focus {
  border-color: #a6251b;
  background: #a6251b !important;
}

.btn-outline-primary,
.btn-outline-primary:focus {
  color: #eb4f24;
  border-color: #eb4f24;
  background: none;
}

.btn-outline-primary:focus {
  color: #eb4f24 !important;
}

.btn-outline-primary:hover {
  border-color: #eb4f24;
  color: #fff !important;
  background: #eb4f24 !important;
}

a.btn-outline-primary:hover,
a.btn-outline-primary:focus {
  color: #fff;
}

.btn-primary.disabled,
.btn-primary:disabled:hover,
.btn-primary.disabled:hover,
.btn-primary:disabled:focus,
.btn-primary.disabled:focus,
.btn-primary:disabled {
  cursor: default;
  color: #fff;
  background: #eb4f24 !important;
  border-color: #eb4f24 !important;
  opacity: 0.5;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover,
.btn-outline-primary.disabled:focus,
.btn-outline-primary:disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #eb4f24 !important;
  background: none !important;
  opacity: 0.5;
}

button svg {
  margin-right: 5px;
}

/* FORM */
#content form,
.form {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
}

.react-datetime-picker__wrapper {
  padding: 4px 0.75rem 5px;
  background: white;
  border: 1px solid #ced4da;
  min-width: 262px;
}

.datepicker > .react-datetime-picker__wrapper {
  min-width: unset;
}

input,
textarea,
select {
  border-radius: 0 !important;
}

input::placeholder,
textarea::placeholder {
  font-style: italic;
}

.form-actions {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.form-actions .btn-link {
  padding-left: 0;
  padding-right: 0;
}

.form-inline select {
  min-width: 150px;
  padding-left: 5px;
  padding-right: 5px;
}

select:disabled {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:disabled::-ms-expand {
  display: none;
}

fieldset.disabled input, fieldset.disabled textarea, fieldset.disabled .react-datetime-picker, fieldset.disabled select {
  pointer-events: none !important;
  box-shadow: none !important;
}

fieldset.disabled .btn, fieldset.disabled tfoot {
  display: none;
}

fieldset.disabled .form-control, fieldset.disabled .form-control[readonly] {
  border-color: transparent;
  background-color: #e9ecef;
  opacity: 1;
}

.form .disabled button {
  display: none;
  pointer-events: none;
}

label {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  color: #666;
  display: block;
}

.form-label {
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
}

input::placeholder,
i,
.font-weight-italic {
  font-style: italic;
}

.datetimepicker {
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='checkbox'],
.form-check-label,
select {
  cursor: pointer;
}

input[type='checkbox']:disabled {
  cursor: default;
}

input.form-check-input {
  border-radius: 100%;
  margin-right: 5px;
  margin-top: 0;
  cursor: pointer;
}

.form-check-input[type='checkbox'] {
  border-radius: 0.25em !important;
  margin-top: 3px;
  min-width: 1em;
}

.form-check-input[type='radio'] {
  border-radius: 100% !important;
  margin-top: 7px;
}

.form-check-input:checked {
  background-color: #eb4f24;
  border-color: #eb4f24;
}

body .rdw-editor-toolbar,
.datetimepicker {
  padding: 0;
  border: 0;
}

.skip-to-content {
  clip: rect(0 0 0 0);
  position: absolute;
}

legend {
  font-size: 0.9rem;
  text-transform: uppercase;
  border-bottom: 1px solid #dee2e6;
  color: #666;
  margin-top: 1.5rem;
}

.viewMode input[type=text],
.viewMode input[type=number],
.viewMode select,
input[type=text]:disabled, input[type=number]:disabled{
  border: 0;
  padding-left: 0;
  background: none;
  pointer-events: none;
}

input.react-datetime-picker__inputGroup__input--hasLeadingZero:disabled {
  padding-left: calc(1px + 0.54em);
}

fieldset.disabled button.react-datetime-picker__calendar-button.react-datetime-picker__button {
  display: none;
}


fieldset.disabled .react-datetime-picker__wrapper {
  border: 0;
  padding-left: 0;
}

.viewMode .form-label,
.viewEditMode .form-label {
  margin-top: 1rem;
  margin-bottom: 0;
}

/* CARDS */
.card {
  border-radius: 3px;
}

.card-body {
  font-size: 0.9rem;
  min-height: 132px;
}

.card-body > legend:first-child {
  margin-top: 0;
}

.card-body p {
  color: #666;
}

iframe {
  width: 100% !important;
}

/* NAVBAR */

.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  background: #eb4f24;
  color: white;
}

.navbar {
  z-index: 9;
  background: #eb4f24;
}

.navbar-nav .nav-item {
  border: 1px solid transparent;
  padding: 0 5px;
}

.navbar-nav .nav-item:last-child {
  border-color: white;
  border-radius: 50px;
}

.navbar-nav .nav-item .nav-link {
  color: white;
}

.nav.flex-column .nav-link {
  position: relative;
  font-size: 0.9rem;
  color: #666;
  border-radius: 0%;
}

.nav.flex-column .nav-link:hover {
  color: #eb4f24;
}

.nav.flex-column .nav-link svg {
  position: absolute;
  left: -10px;
  top: 10px;
  color: #eb4f24;
}

.nav-pills .nav-link {
  border-left: 2px solid transparent;
  padding-left: 10px;
  padding-right: 0px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: none;
  border-left: 2px solid #eb4f24;
  color: #eb4f24;
  font-weight: bold;
}

.nav-pills .nav-link.active.activeError,
.nav-pills .show > .nav-link.activeError {
  border-left: 2px solid transparent;
}

/* TOASTS */
.toast-container {
  margin: 10px auto;
}

/* BADGES */
.navbar .badge {
  position: relative;
  top: -3px;
  right: -2px;
}

/* TOOLTIPS */
.toolTip.btn-primary.btn,
.toolTip.btn-primary.btn:hover,
.toolTip.btn-primary.btn:focus {
  background: none !important;
  color: #666;
  border: 0 !important;
  padding: 0;
  margin-left: 5px;
  margin-top: -3px;
  outline: none !important;
  box-shadow: none !important;
  display: inline-block !important;
}

.toolTip.btn-primary.btn:hover,
.toolTip.btn-primary.btn:focus {
  color: #999 !important;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
}

/* TABS */
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: none;
}

.nav-tabs .nav-link {
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 5px;
}

.summaryTabs .nav-tabs .nav-link {
  padding-left: 16px;
  padding-right: 16px;
}

.otherIncentive:nth-child(1) {
  border: none !important;
}

.financialBoards .accordion .accordion-item {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.nav-tabs .nav-item .nav-link {
  background: none;
  color: #eb4f24;
}

.tableWithBorders table tr td {
  padding: 5px;
}

.tableWithBorders table tr th {
  padding: 5px;
}

.tableWithBorders table {
  border: 1px solid #ccc;
}

.tableWithBorders table tr th {
  border-left: 1px solid #ccc;
  font-weight: normal;
  text-transform: uppercase;
  background: #eee;
}

.tableWithBorders table tr td:nth-child(odd) {
  border-left: 1px solid #ccc;
}

.withBorderLeft {
  border-left: 1px solid #ccc;
  border-width: 1px;
  border-color: #ccc;
}

.vertical {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #a6251b;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  color: #333;
  border-bottom: 1px solid white;
  font-weight: bold;
}

.tab-pane {
  border-top-color: transparent !important;
}

.nav.nav-tabs.nav-justified {
  display: flex;
  align-items: flex-end;
}

hr {
  border-color: #eb4f24;
  opacity: 85%;
}

/* HEADER */
.masthead {
  background-color: #f59f40;
  color: #fff;
}

.banner {
  height: 375px;
  border-bottom: 15px solid #eb4f24;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url('../images/banner.png');
}

.masthead .masthead-subheading {
  font-style: normal;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin: 7rem 0 0;
}

.masthead .masthead-heading {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
  margin: 1rem 0 6rem;
}

.navbar-toggler {
  border: 0;
  padding-left: 0;
  padding-right: 0;

}

.nav-item.exit-btn {
  border-width: 0pt;
}

.nav-item.exit-btn .btn.btn-primary {
  border-color: #fff;
  color: #fff;
  padding-top: 0.25rem;
  margin-top: 0.25rem;
  max-width: 260px;
  overflow: hidden;
}

/* FOOTER */
footer {
  border-top: 15px solid #eb4f24;
}

footer img {
  height: 41px;
}

footer .social-contact {
  width: 45px;
  height: 45px;
}

footer .social-contact svg {
  vertical-align: bottom;
}

/* TABLE */

.table {
  overflow-x: scroll;
  width: 100%;
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table::-webkit-scrollbar {
  display: none;
}

.table th,
.table td {
  border-top: 0;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
}

.table th label {
  margin: 0;
}

.table thead th {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.table th {
  font-weight: normal;
  font-size: 0.9rem;
}

.table-item-bold {
  font-weight: bold;
}

.table-item-big input {
  height: 82px;
}

.table-item-regular input {
  height: 32px;
}

.w-40 {
  width: 40%;
}


/* RESULTS */

.searchFilters select,
.searchFilters input,
.searchFilters .form-control {
  border-radius: 5px !important;
  border-color: #999 !important;
}

.searchFilters .btn {
  border-radius: 5px !important;
}

.accordion .searchResults {
  min-height: 0px;
}

.searchResults{
  min-height: 400px;
}

.searchResults select {
  padding: 0;
  border: 0;
  color: #eb4f24;
  text-align: right;
}

/* IHRU RESULTS */
.results {
  word-break: break-word;
  font-size: 1rem;
  list-style-type: none;
  padding: 0 0.5rem;
  margin: 0 0 1.5rem;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.results li {
  border-bottom: 1px solid #dee2e6;
}

.results li:last-child {
  border-bottom: none;
}

.search-results,
.no-results {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  top: -13px;
  width: 100%;
  z-index: 99;
  padding: 10px 0;
}

.results .btn {
  visibility: hidden;
}

.results .row:hover {
  background: #fafafa;
  cursor: pointer;
}

.notPointer:hover {
  background: #fafafa;
  cursor: default !important;
}

.results .row:hover .btn {
  visibility: visible;
}

.no-results {
  font-style: italic;
  display: block;
  padding: 5px 10px;
}

/* EMPTY STATE */
.empty-state {
  padding: 3rem 2rem 1.5rem;
  text-align: center;
  border: 1px solid #ccc;
}

.empty-state > svg {
  font-size: 2rem;
  color: #999;
  display: block;
  margin: 0 auto 1.8rem;
}

.empty-state p {
  margin-bottom: 2rem;
  color: #666;
  font-style: italic;
}

.tab-pane .empty-state {
  border: 0;
  background: #f9f9f9;
}

/* ALERTS */
.alert svg {
  font-size: 2rem;
}

.accordion-header .accordion-button {
  background: white !important;
  color: #eb4f24 !important;
  font-weight: bold;
  outline: 0 !important;
  box-shadow: none !important;
}

.accordion-header .accordion-button::after {
  color: #eb4f24 !important;
}

.accordion-item {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

/* LOADING */
.loading {
  padding: 230px 0;
  text-align: center;
  font-size: 4rem;
  color: #666;
}

.loader {
  border: 5px solid #eb4f24;
  border-top: 5px solid #ccc;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 3s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    top: -20px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/* FILE UPLOAD */
input[type='file'] {
  margin-left: -2px !important;
}

input[type='file'].empty {
  color: transparent;
}

input[type='file']::file-selector-button {
  display: none;
}

/* IMAGE UPLOAD */
body .fileContainer {
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

body .fileContainer p {
  font-size: 0.8rem;
}

body .fileContainer .chooseFileButton {
  color: #eb4f24;
  border: 1px solid #eb4f24;
  background: none;
  border-radius: 3px;
}

body .fileContainer .chooseFileButton:hover,
body .fileContainer .chooseFileButton:focus {
  border-color: #eb4f24;
  background: #eb4f24 !important;
  color: white;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-35 {
  width: 35% !important;
}

/* PAGINATION COMPONENT */
ul.pagination {
  margin: 0;
}

.page-item.active .page-link,
.page-item.active .page-link:hover,
.page-item.active .page-link:focus {
  background-color: #eb4f24;
  border-color: #eb4f24;
  color: white;
}

.page-link,
.page-link:hover,
.page-link:focus {
  color: #eb4f24;
}

/* CANDIDATURE */
.infoText {
  font-style: italic;
  display: flex;
  align-items: center;
  font-style: italic;
}

.infoText svg {
  font-size: 1.2rem;
  margin-right: 8px;
}

.infoBlock {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  min-height: 390px;
}

.infoBlock svg {
  color: #eb4f24;
  font-size: 4rem;
  margin: 3rem 0 2rem;
}

/* ICONS */
.normalIconSize {
  font-size: 1.4rem;
  margin-right: 8px;
}

.back-button {
  font-size: 2.2rem;
  color: #d45246;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  line-height: 1;
}

.back-button:hover,
.back-button:focus {
  color: #a6251b;
}

/* MOBILE */
@media only screen and (min-width: 769px) {
  .tooltip-inner {
    max-width: 650px;
    text-align: left;
  }

  .form-inline .form-group {
    max-width: 190px;
    flex-flow: nowrap;
  }

  .form-inline .form-group {
    margin-bottom: 15px;
  }

  .form-inline .form-group.big-input {
    max-width: 250px;
  }

  .navbar-nav .nav-item {
    margin-right: 1.5rem;
  }

  .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .back-button {
    font-size: 40px;
    margin-left: -45px;
    margin-top: -4px;
    position: absolute;
  }
}

@media only screen and (max-width: 767px) {
  #destinatarios img {
    max-width: 280px;
  }

  .back-button {
    font-size: 40px;
    position: absolute;
    top: 7px;
    left: 0;
    color: white;
    z-index: 99999;
  }

  #content {
    position: initial;
  }

  .navbar-brand {
    margin-left: 40px;
  }

  .searchFilters input, .searchFilters select{
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-collapse {
    text-align: center;
    display: block;
  }

  .navbar-collapse li {
    border: 0 !important;
    text-align: center;
  }

  .navbar-collapse li button {
    width: 100%;
  }
}

@media only screen and (max-width: 1480px) {
  .banner {
    height: 435px;
    border-width: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    height: 190px;
    border-width: 8px;
  }
}

@media only screen and (max-width: 425px) {
  .banner {
    height: 110px;
    border-width: 5px;
  }

  .masthead .masthead-heading {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1rem 0 3rem;
  }
}

.inputSuffix + span {
  background: none;
  color: #999;
}

.inputSuffix {
  border-right: 0;
}

.labelCompact {
  color: #666;
  display: block;
}

.labelCompact + div {
  padding-top: 0 !important;
}

.labelCompact + div > .bg-light {
  margin-top: 0 !important;
}

.messagesHistoric > div:last-child {
  border-bottom: none !important;
}

.messagesHistoric > div:first-child {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

li.nav-item.exit-btn > div > button {
  text-overflow: ellipsis;
  display: block;
}

.user-area .accordion-button,
.user-area .card-body {
  padding: 16px 16px;
  display: block;
  min-height: auto;
}

.user-area .accordion-body a {
  font-size: 1.1rem;
  line-height: 2.4rem;
  text-decoration: none;
  display: block;
}

.user-area .card-body a {
  font-size: 1.75rem;
  text-decoration: none;
  display: block;
}

.user-area svg {
  font-size: 2rem;
}

.after-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-area .after-icon svg {
  font-size: 1.4rem;
  color: #ccc;
}

.user-area p {
  font-weight: normal;
}

.accordion-item {
  margin-top: 1.5rem;
}

.accordion-header .accordion-button {
  background: white !important;
  color: #eb4f24 !important;
  font-weight: bold;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.accordion {
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var(--bs-body-color)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --bs-accordion-btn-focus-border-color: red;
}

.div-table {
  margin-top: 1.5rem;
  width: 100%;
  display: table;
  border: 1px solid black;
  border-collapse: collapse;
}

.div-table-caption {
  display: table-caption;
  color: #666;
}

.div-table-header-row {
  display: table-header-group;
  background-color: #eb4f24;
  color: white;
  font-weight: bold;
  border: 1px solid black;
  border-collapse: collapse;
}

.div-table-header-cell {
  display: table-cell;
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
}

.div-table-body {
  display: table-row-group;
}

.div-table-row {
  display: table-row;
}

.div-table-cell {
  display: table-cell;
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
}

.div-table-footer {
  display: table-footer-group;
  font-weight: bold;
}

.div-table-footer-cell {
  display: table-cell;
  border-bottom: 1px solid black;
}

.is-invalid.input-group-text {
  border-color: #dc3545;
  color: #dc3545;
}

.toolTipError > .tooltip-inner {
  background-color: transparent;
}

.toolTipError {
  background-color: transparent;
}

.invalid-feedback {
  display: block;
}

.indicators table {
  font-size: 0.9rem;
}
.indicators thead th {
  font-weight: normal;
  color: #666;
  line-height: 1rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  padding: 2px 4px !important;
  min-width: 100px;
}
.indicators table td {
  padding: 2px 4px !important;
}

.indicators table tr {
  border-bottom: 1px solid #eee;
}

.indicators .pagination {
  margin-top: 15px;
}

a.payment-entry-document-link {
  text-decoration: none;
}

.selectWithIconAtRight{
  background-position: right 0.1rem top 50%;
  padding-right: 0;
  padding-left: 5px;
}

.modal-65 {
  max-width: 65vw;
  min-width: 50vw;
}

.modal-35 {
  max-width: 35vw;
  min-width: 35vw;
}

.bg-card-panel{
  background: #eee;
}

tr.invalid-row, tr.invalid-row > * {
  color: #dc3545;
}

.form-range::-webkit-slider-thumb, .form-range:hover::-webkit-slider-thumb, .form-range:active::-webkit-slider-thumb {
  background-color: var(--empxxi-accent-color);
}
.form-range::-moz-range-thumb, .form-range:hover::-moz-range-thumb, .form-range:active::-moz-range-thumb {
  background-color: var(--empxxi-accent-color);
}

.form-range::-ms-thumb, .form-range:hover::-ms-thumb, .form-range:active::-ms-thumb {
  background-color: var(--empxxi-accent-color);
}

.form-range:active::-webkit-slider-thumb, .form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .25rem var(--empxxi-accent-color-25opacity);
}

.form-range:active::-moz-range-thumb, .form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .25rem var(--empxxi-accent-color-25opacity);
}

.form-range:active::-ms-thumb, .form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .25rem var(--empxxi-accent-color-25opacity);
}